.dash-stats-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.dash-stat-block {
  flex: 1 1 auto;
  margin: 0.25rem 0;
  min-width: 100%;
  padding: 1.25rem 0.75rem 0.75rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #ececec;
  background-color: #fff;
}

.dash-stat-header {
  color: var(--a-navheader);
  font-size: 0.75em;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

.dash-stat-value {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 0.25rem;
  
  color: var(--a-theme);
  font-size: 2.5em;
  font-weight: 700;
}

.dash-stat-value i {
  margin: 0;
  padding: 0;
  font-size: 0.7em;
}


.dash-stat-block h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.dash-referrers {
  text-align: left;
  max-width: 400px;
}

.dash-referrer-list {
  margin:0.5rem 0.25rem 0 0.25rem;
}

.dash-referrer-item {
  font-style: italic;
  color: var(--a-fontgrey);
  display: flex;
}

.dash-referrer-count {
  font-size: 0.8em;
  color: var(--a-fontgrey);
  margin-right: 1rem;
}

.dash-referrer-number {
  margin-left: auto;
}

.stat-month-list-item {
  cursor: pointer;
}
.stat-month-list-item:hover {
  color: #2d2d2d;
}




@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .dash-stats-row {
    flex-direction: row;
  }

  .dash-stat-block {
    min-width: inherit;
  }

  .dash-stat-block {
    margin: 0.5rem;
    padding: 1.5rem 2rem 1rem 2rem;
  }
  .dash-stat-block h3 {
    margin-bottom: 1rem;
  }
}