.mcg-notice-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.mcg-notice-form-item {
  flex: 1;
  padding: 0.5rem;
}

.mcg-notice-form-item:last-child {
  flex: 1 1 50%;
}

.mcg-drop-area {
  min-width: 350px;
  max-width: 100%;
}

.mcg-notice-form-item label {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px;
}

.mcg-label-text {
  padding: 7px 10px;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
}

.mcg-notice-form-item label input {
  padding: .375rem .75rem;

  line-height: 1.5;
  font-size: 1rem;
  color: var(--a-themedark);

  background-color: var(--a-backgroundlight);
  background-clip: padding-box;

  border: 1px solid var(--a-border);
  border-radius: .25rem;

  transition: border-color .25s ease-in-out;
}
.mcg-notice-form-item label input:hover {
  border-color: var(--a-themelight);
}

.mcg-form-button {
  padding: 15px;
  color: var(--a-fontlight);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--a-theme);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;

  transition: background-color .1s ease-in-out;
}

.mcg-form-button:hover {
  background-color: var(--a-themelight);
}

.mcg-inline-drop-area {
  height: 100%;
}

.mcg-restrict-width {
  width: 100%;
}

.ql-editor {
  min-height: 100px;
}


/* Notice */
.mcg-notice-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid var(--a-highlight);
  background-color: var(--a-secondarylight);

  border-radius: 0.25rem;

  overflow: hidden;
}

.mcg-notice-item-image {
  max-width: 100%;
  padding: 0 15px;
}

.mcg-notice-descriptors {
  display: flex;
  width: 100%;
  padding: 1rem;
}

.mcg-notice-details {
  width: 100%;
  text-align: left;
  margin-left: 1rem;
}

.mcg-notice-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mcg-notice-button {
  margin: 2px;
  padding: 0;

  display: flex;
  flex-direction: column;
  padding: 7px 7px 5px 7px;

  color: var(--a-fontlight);
  background-color: var(--a-theme);
  border-radius: 0.15rem;

  text-align: center;
  font-size: 0.75em;

  cursor: pointer;
  transition: background-color .1s ease-in-out;
}
.mcg-notice-button i {
  font-size: 1.5em;
}

.mcg-notice-button:hover {
  background-color: var(--a-themelight);
}

.mcg-notice-item-title {
  font-size: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
}

.mcg-notice-item-link {
  font-size: 0.9em;
  font-style: italic;
}

.mcg-notice-item-loading {
  width: 100%;
  min-height: 220px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
}

.mcg-large-text {
  font-size: 1.5em;
  line-height: 1.5;
}

@media (min-width: 550px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .mcg-notice-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mcg-notice-item-image {
    max-width: 200px;
  }

  .mcg-notice-buttons {
    flex-direction: column;
  }
}
@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .mcg-notice-item-image {
    max-width: 250px;
  }
}