.mcg-login-logo {
  margin: 10rem 0 5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.mcg-login {
  width: 400px;
  margin: auto;
  text-align: center;
  padding: 2rem;

  border: 1px solid var(--a-border);
  background-color: var(--a-secondarylight);
  border-radius: 0.25rem;
  margin-top: 3rem;
}

.mcg-login h3 {
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.mcg-login-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  margin-bottom: 1rem;
}
.mcg-login-input input {
  width: 100%;
  font-size: 1.25em;
  padding: 0.5rem 1rem 0.5rem 3rem;
}
.mcg-login-input i {
  position: absolute;
  left: 14px;
  top: 11px;
  height: 100%;
  color: var(--a-fontdarksub);
  font-size: 1.3em;
}