.mcg-form-field {
  display: block;
  position: relative;
  text-align: left;
  margin: 0.5rem;
  flex-grow: 1;
}

.mcg-form-field textarea:focus, .mcg-form-field input:focus {
  outline: none;
}

.mcg-form-field label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.25rem;
  width: 100%;

  text-transform: uppercase;
  font-style: italic;
  font-weight: 500;
  font-size: 1.1em;

  color: var(--fontdarkfade);

  transition: 0.3s;
  cursor: pointer;
}
section:nth-child(odd) .mcg-form-field label {
  color: var(--fontlight);
}

.mcg-form-field input, .mcg-form-field textarea {
  width: 100%;
  padding: 0.25rem 0;

  font-size: 1.2em;

  border: none;
  border-bottom: 1px solid var(--border);
}


section:nth-child(odd) .mcg-form-field input, section:nth-child(odd) .mcg-form-field textarea {
  background-color: var(--theme);
  color: var(--fontlight);
}

.mcg-form-field input:focus, .mcg-form-field textarea:focus {
  border-bottom: 1px solid var(--highlightdark);
}
section:nth-child(odd) .mcg-form-field input:focus, section:nth-child(odd) .mcg-form-field textarea:focus {
  border-bottom: 1px solid var(--highlight)
}

.mcg-form-field input:focus + label, .mcg-form-field textarea:focus + label {
  top:-1rem;
  bottom: 1rem;
  font-size: 0.8em;
  color: var(--highlighthover);
}
.mcg-form-field input:valid + label, .mcg-form-field textarea:valid + label {
  opacity: 0;
}

.form-required {
  color: var(--error);
}
section:nth-child(odd) .form-required {
  color: var(--highlight);
}