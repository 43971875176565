.mcg-details-item:last-child {
  padding-bottom: 0.5rem;
}

.mcg-details-descriptor {
  width: 100%;
  text-align: left;
  padding: 0.75rem 0.5rem 0.25rem 0.5rem;
}

.mcg-details-input {
  width: 100%;
}
.mcg-details-input input {
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.35rem 0.5rem;
  border: 1px solid var(--a-border);
  color: var(--a-fontdarksub);
}


@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .mcg-details-item {
    display: flex;
    align-items: center;
  }

  .mcg-details-descriptor {
    width: inherit;
    min-width: 250px;
    text-align: right;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
  }
}