.app-options select {
  height: 38px;
  font-size: 1.2em;
  padding: 5px 10px;
  color: var(--a-fontdark);
  background-color: var(--a-backgroundlight);
  background-clip: padding-box;
  border: 1px solid var(--a-border);
  border-radius: .25rem;
  transition: border-color .25s ease-in-out;
}