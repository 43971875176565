.mcg-articles {
  max-width: 800px;
  margin: 0 auto;
}

.mcg-story {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-top: 2rem;
  margin-left: 1.25rem;
}

.story-inner {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.mcg-story a {
  text-decoration: none;
}

.story-content {
  margin: 0.25rem 0;
}

.story-bubble {
  position: absolute;
  left: -7rem;
  top: 15%;
  width: 90px;
  height: 90px;
}

.story-bubble img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.story-source a {
  color: var(--linkdark);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9em;
  font-style: italic;
}
section:nth-child(odd) .story-source a {
  color: var(--linklight);
}

.story-headline {
  color: var(--fontdark);
  font-size: 1.25em;
  line-height: 1.25em;
  font-weight: 600;
}

section:nth-child(odd) .story-headline {
  color: var(--fontlight);
}

.story-message {
  color: var(--fontdarkfade);
  font-style: italic;
  font-size: 0.9em;
}
section:nth-child(odd) .story-message {
  color: var(--fontlightfade);
}

.story-message p {
  margin: 0.25rem 0;
}

.story-created {
  color: var(--fontdarkfade);
  font-style: italic;
  font-size: 0.9em;
}
section:nth-child(odd) .story-created {
  color: var(--fontlightfade);
}


@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .mcg-story {
    margin-left: 0;
  }

  .story-bubble {
    position: relative;
    left: inherit;
    top: inherit;
    width: 150px;
    min-width: 150px;
    height: 150px;
    margin-right: 2rem;
  }

  .story-content p {
    margin-top: 0.25rem !important;
    font-weight: 400;
  }
}


.mcg-articles-horizontal {
  max-width: 1200px;
  margin: 2rem auto 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.mcg-story-horizontal {
  max-width: 30%;
  min-width: 300px;
  margin: 0 1rem 2rem 1rem;
}
.story-horizontal-image {
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  object-position: center;
  object-fit: cover;
}
.story-horizontal-image img {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  object-position: center;
  object-fit: cover;
}




/* OTHER FACEBOOK STYLES */
/*
.fb-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  margin: 2rem auto 0 auto;
}

.fb-item {
  display: flex;
  flex-direction: column;

  flex: 1;
  min-width: 300px;
  margin: 1.25rem 0;
  padding: 1rem;

  background-color: #fafafa;
  border: 1px solid #eee;
  color: #171720;
  border-radius: 0.25rem;
}
.fb-item:first-child {
  margin-left: 0;
}
.fb-item:last-child {
  margin-right: 0;
}
.fb-item a {
  display: block;
}

.fb-display-image {
  display: flex;

  justify-content: center;
  align-items: center;

  height: 250px;
  width: 100%;

  overflow: hidden;
}

.fb-display-image img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}

/*.fb-item img {
  width: 100%;
}*/
/*
.fb-message {
  padding: 0;
  margin: 0.75rem 0.25rem;
}

.fb-options {
  margin-top: auto;
  bottom: 0;
  display: flex;
  align-items: center;
}

.fb-options a {
  background-color: #315994;
  color: #fff;
  padding: 0.5rem;
  margin: 0.25rem;
  flex-grow: 1;
  font-size: 0.8em;
  text-decoration: none;
  cursor: pointer;

  border-radius: 0.25rem;

  transition: background-color 0.15s ease-in-out;
}
.fb-options a:hover {
  background-color: #171720;
}
.fb-options a i {
  padding-right: 0.5rem;
}

@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
/*  .fb-item {
    margin: 1rem;
  }
}*/