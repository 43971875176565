/* #315994 */

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  display: flex;

  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  font-size: 1.4rem;
  align-items: center;

  background-color: var(--backgroundlight);

  border-bottom: 1px solid var(--border);
  transition: padding 0.2s ease-in-out;
}


.h-logo img {
  max-height: 40px;

  transition: max-height 0.2s ease-in-out;
}

.h-sticky .h-logo img {
  max-height: 40px;
}

.h-nav-button {
  padding: 0.5em 1rem;
  cursor: pointer;
}
.h-nav-button-menu {
  margin-left: auto;
}

.h-nav-list {
  display: none;
  margin-left: auto;
  font-size: 1.1rem;
  font-weight: 450;
}

.h-nav-list a {
  text-decoration: none;
  padding: 0.25rem 0.75rem;
  color: var(--linkdark);

  transition: color 0.4s ease-in-out;
}
.h-nav-list a:hover {
  color: var(--linkdarkhover);
}

.logo-text-replace {
  font-weight: 700;
  font-size: 1.25em;
  font-family: 'Fjalla One', sans-serif;
}

@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .header {
    padding: 1.25rem 2.0rem 1.25rem 1.25rem;
  }
  .h-logo img {
    max-height: 65px;
  }
}
@media (min-width: 980px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .h-nav-list {
    display: flex;
  }
  .h-nav-button {
    display: none;
  }
  a.nav-reduce-visibility {
    color: var(--highlighthover);
  }
}
.h-sticky {
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
}