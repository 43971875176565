.mcg-service-item {
  display: flex;
  align-items: center;

  padding: 10px 20px 10px 10px;
  margin: 5px 10px;

  border: 1px solid var(--a-border);
  border-radius: 0.25rem;
  background-color: var(--a-secondary);

  cursor: pointer;
}

.mcg-service-input, .mcg-service-input input {
  width: 100%;
}

.mcg-service-insert {
  display: flex;
  align-items: center;

  padding: 1rem;
  margin: 0.5rem;
}

.mcg-service-add {
  cursor: pointer;
  text-align: center;
  width: 175px;
  color: var(--a-fontlight);

  background-color: var(--a-theme);
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  margin-right: 1rem;

  transition: background-color 0.2s ease-in-out;
}
.mcg-service-add i {
  padding: 0 0.1rem 0 0;
}
.mcg-service-add:hover {
  background-color: var(--a-themelight)
}