.design-color-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 80%;
}

.design-color-list .mcg-details-item {
  display: flex;
  flex-direction: row;
}

.design-color-list .mcg-details-descriptor {
  min-width: 200px;
  text-align: right;
}

.design-style-container {
  display: flex;
  flex-direction: row;
}

.design-style-box {
  width: 70px;
  height: 40px;

  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.3rem;
  margin: 0.25rem;

  border: 1px solid var(--a-border);
  cursor: pointer;
}

.design-style-color {
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
}

.design-style-selector {
  position: absolute;
  max-height: 0;
  overflow: hidden;
  margin-left: 75px;
  border-radius: 0.35rem;
  border: none;

  transition: max-height 0.2s ease;
}
.design-style-selector.show {
  border: 1px solid var(--a-border);
  max-height: 100%;
}