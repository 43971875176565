.featured-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feature-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
}
.feature-item:hover {
  background-color: var(--highlight);
  color: var(--fontdark);
}

.f-image {
  width: 200px;
  height: 170px;
  overflow: hidden;
  border-radius: 0.25rem;
  margin: 1rem 0;
}

.f-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.f-info-container {
  display: flex;
  width: 200px;
  text-align: left;
}

.f-info {
  margin-right: auto;
}

.f-title {
  font-weight: bold;
}

.f-subtitle {
  font-style: italic;
  font-size: 0.9em;
}

.f-price {
  text-align: right;
}




@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .featured-list {
    flex-direction: row;
    width: 1260px;
    max-width: 100%;
    margin: 0 auto;
  }
}