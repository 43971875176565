section {
  color: var(--fontdark);
  padding: 2.5rem 3rem;
  text-align: center;
}
section p {
  margin-top: 1.5rem;
  font-size: 18px;
}

section:nth-child(odd) {
  background-color: var(--background);
  color: var(--fontlight);
}
section:nth-child(odd) a {
  color: var(--fontlight);
}


.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.service-title {
  margin-bottom: 2rem;
  text-align: center;
  padding: 0.25rem 0;
}
.service-title h2 {
  margin: 0;
  padding: 0.25rem 0;
}

ul.services {
  text-align: left;
  min-width: 300px;
  margin: 0;
}

.service-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  padding: 0.25rem 0;
}

.service-element {
  font-size: 1.25em;
}

.map-section {
  margin-top: 0;
  padding: 0;
  height: 400px;
}

.animation {
  animation-duration: 1s;
}

.mcg-content-area {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 2rem;
}

@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
  section {
    padding: 3.75rem 5rem;
  }

  section p {
    max-width: 900px;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  .services {
    margin-top: 2rem;
  }
  
  .service-title {
    text-align: right;
    max-width: 200px;
    margin-right: 5rem;
    margin-bottom: 0;
  }
}

.mcg-section-override {
  margin: 0;
  padding: 0;
}


.checkout-container {
  z-index: 2500;
}

.checkout-success-check, .checkout-success-header {
  margin: 2rem 0 1rem 0;
}

.checkout-card-container h4 {
  margin: 2rem 0 1rem 0;
}

.add-to-cart {
  cursor: pointer;
}

.mcg-select {
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0.25rem 0.5rem;
}


.mcg-list-gallery {
  overflow: auto;
  margin-top: 2rem !important;
  max-width: 1100px !important;
}




.header_1tk9zku {
  max-width: 90% !important;
}
#lightboxBackdrop > div > div > figure > img, .image_1swebtw-o_O-imageLoaded_zgbg08 {
  max-width: 80% !important;
}



.mcg-blockdetect {
  margin: 6rem 0 0 0;
  padding: 1rem;
  background-color: var(--warning);
  color: #333333;
  text-align: center;
}