.mcg-file-manager {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.mcg-filemanager-item {
  margin: 5px;
}



.mcg-filemanager-controls,
.mcg-filemanager-controls * {
  box-sizing: border-box;
}

.mcg-filemanager-controls {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
	font-size: 40px;

  max-width: 250px;

  overflow: hidden;
}
.mcg-filemanager-controls img {
  max-width: 100%;
}

.mcg-filemanager-display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mcg-filemanager-display.layer-top {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--a-backgrounddark);
  color: var(--a-fontlight);
  opacity: 0;
  display: flex;

  transition: opacity 0.25s ease-in-out;
}

.mcg-filemanager-display.layer-top:hover {
  opacity: 1;
}

.mcg-filemanager-controls-option {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px 10px 10px 10px;
  margin: 0 3px;

  cursor: pointer;
}

.mcg-controls-option-subtext {
  padding-top: 2px;
  font-size: 16px;
  font-style: italic;
}

.mcg-filemanager-display-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  padding: 10px;
  min-height: 100px;
}