.a-market-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.a-market-create-wrapper {
  width: 100%;
  background: #fdfdfd;
  padding-bottom: 5rem;
}

.a-market-create-back {
  cursor: pointer;
  color: var(--a-theme);

  margin-bottom: 0.5rem;

  transition: color 0.2s ease;
}
.a-market-create-back:hover {
  color: var(--a-themelight);
}

.a-market-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.a-market-actions select {
  cursor: pointer;
  border-radius: 0.25rem;
  border-color: rgba(0, 0, 0, 0.3);
  padding: 0.5rem;
  width: 100%;
}
.a-market-filter-category {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 2rem;
}

.a-market-filter-search {
  padding: 0 2rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
.a-market-filter-search input {
  width: 100%;
  padding: 0.75rem;
  border-color: rgba(0, 0, 0, 0.3);
}
.market-button {
  margin-bottom: 1rem !important;
}

@media (min-width: 900px) {
  .a-market-actions {
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  .a-market-actions select {
    width: inherit;
  }
  .a-market-filter-category {
    width: inherit;
    margin-right: 1rem;
    margin-bottom: 0;
    padding: 0;
  }
  .a-market-filter-search {
    width: inherit;
    margin-right: 1rem;
    padding: 0;
    margin-bottom: 0;
  }
  .a-market-filter-search input {
    width: 400px;
  }
  .a-market-filter-sort {
    margin-right: auto;
  }

  .market-button {
    margin-left: auto;
    margin-bottom: inherit !important;
  }
}

.a-market-create-option-list {
  margin: 1rem;
}

.a-market-option-item {
  cursor: grab;

  display: flex;
  align-items: center;
  
  padding: 0 0.5rem;
  margin: 0.25rem 0;

  border-radius: 0.25rem;

  border: 1px solid var(--a-border);
  background-color: var(--a-secondary);
  overflow: hidden;
}
.a-market-option-item input {
  width: 100%;
  margin: 0.5rem;
  padding: 0.5rem;
}

.a-market-option-sort-icon, .a-market-option-delete {
  padding: 0.75rem 0.5rem;

  transition: color 0.2s ease;
}
.a-market-option-delete {
  cursor: pointer;
}
.a-market-option-delete:hover {
  color: var(--error);
}

.a-market-create-variation-section {
  width: 100%;
}



.a-market-filter-search input::placeholder {
  font-size: 0.9em;
}
.a-market-table {
  width: 100%;
  
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}
.a-market-table tr {
  height: 44px;
  border-bottom: 1px #d5d9dc solid;
}
.a-market-table-header {
  box-sizing: border-box;
  background-color: #ebedef;
}
.a-market-table-header tr {
  box-sizing: border-box;
  border-bottom: 1px #d5d9dc solid;
}

.a-market-table-item {
  transition: background-color 0.2s ease;
}

.market-icon {
  padding: 0.5rem;
}

.a-market-table-clickable {
  cursor: pointer;
}

.a-market-table-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.a-market-table-title {
  padding-left: 20px;
}
.a-market-table-price, .a-market-table-stock {
  text-align: right;
}
.a-market-table-sku, .a-market-table-category, .a-market-table-stats {
  text-align: center;
}
.a-market-table-featured {
  cursor: pointer;
  color: var(--theme);
  width: 70px;

  transition: 0.2s color ease;
}
.a-market-table-featured:hover {
  color: var(--themehighlight);
}
.market-promoted, .market-featured {
  transition: 0.2s color ease;
}
.market-promoted:hover, .market-featured:hover {
  color: var(--themehighlight);
}
.market-not-promoted, .market-not-featured {
  color: rgba(0,0,0,0.1);
}


.mcg-missing-component-alert {
  position: relative;
  margin-right: 0.5rem;
  color: var(--warning);
}

.alert-tooltip {
  display: none;
  z-index: 2;
  width: 20vw;
  min-width: 250px;
  position: absolute;
  left:0;
  background-color: #eee;
  border: 1px solid #ddd;
  color: #333;
  padding: 1rem;
  border-radius: 0.25rem;
}
.alert-tooltip.show {
  display: block;
}


.a-market-table-actions {
  cursor: pointer;
  position: relative;
  color: var(--theme);
  text-align: center;

  width: 70px;

  transition: 0.2s color ease;
}
.a-market-table-actions:hover {
  color: var(--themehighlight);
}

.market-actions-menu {
  display: none;
  z-index: 2;

  position: absolute;
  right: 0;

  text-align: left;
  min-width: 230px;
  margin-right: 20px;

  box-shadow: 0 2px 8px rgba(0,0,0,.15), 0 4px 24px rgba(0,0,0,.15);
  background-color: #fff;
  border: 1px solid #eee;
  color: var(--linkdark);
  border-radius: 0.25rem;
}
.market-actions-menu.show {
  display: block;
}

.market-actions-menu div {
  padding: 0.4rem 1rem;
  
  transition: background-color 0.2s ease;
}
.market-actions-menu div:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.market-hide {
  display: none;
}




/* create */

.a-market-create-top-section {
  display: flex;
  flex-wrap: wrap-reverse;
}

.a-market-create-details {
  width: 100%;
  text-align: left;
  padding: 0.5rem;
}

@media (min-width: 1000px) {
  .a-market-create-top-section {
    flex-wrap: nowrap;
  }

  .a-market-create-details {
    width: 50%;
  }
}
.a-market-create-detail-wrapper {
  padding: 0.5rem;
}

.a-market-create-input {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.a-market-create-input .am-create-label {
  width: 100px;
}

.am-create-status-select select {
  cursor: pointer;
  border-radius: 0.25rem;
  border-color: rgba(0, 0, 0, 0.15);
  padding: 0.4rem 0.5rem;
  width: 150px;
}

.a-market-create-description {
  align-items: flex-start;
  flex-direction: column;
}

.a-market-create-description .am-create-label {
  margin-bottom: 0.5rem;
}
.am-create-description-multiline {
  margin: 1rem;
  max-width: 100%;
}
.am-create-description-multiline textarea {
  display: block;
  width: 100%;
}

.am-create-description-multiline textarea {
  border-radius: 0.25rem;
  padding: 0.35rem 0.5rem;
  border: 1px solid #ddd;
  line-height: 1.5em;
}


.a-market-create-variation-section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 1rem 0;
  margin: 0rem 1rem 0 1rem;
}

.a-market-create-section-header {
  font-size: 1.5em;
  min-width: 400px;
}


.a-market-create-switch {
  margin-top: 8px;
}

.a-market-table-row {
  position: relative;
  display: flex;
  text-align: left;
  margin: 0 1rem 0 1rem;
}
.a-market-table-header {
  margin: 1rem 1rem 0 1rem;
  font-weight: bold;
}

.a-market-table-cell {
  width: 25%;
}

.a-market-table-header > .a-market-table-cell {
  padding: 1rem;
}

.a-market-table-cell.am-sku, .a-market-table-cell.am-sku input {
  text-align: center;
}
.a-market-table-cell.am-price, .a-market-table-cell.am-stock,
.a-market-table-cell.am-price input, .a-market-table-cell.am-stock input {
  text-align: right;
}

.a-market-table-cell input {
  border-radius: 0;
  width: 100%;
  padding: 1rem;
  border-top: none;
  border-right: none;
}
.a-market-table-cell:last-child input {
  border-right: 1px solid #ddd;
}

.a-market-table-cell.am-delete {
  cursor: pointer;
  position: absolute;
  padding: 0.75rem;
  right: -20px;
  text-align: center;
  width: 30px;
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.5);

  transition: color 0.2s ease;
}
.a-market-table-cell.am-delete:hover {
  color: var(--error);
}

.a-market-create-variation-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.a-market-create-save-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgb(243, 243, 243);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
  box-shadow: 0 2px 6px rgba(0,0,0,.15), 0 4px 24px rgba(0,0,0,.15);
}
.a-market-create-button {
  width: 300px !important;
  margin-left: auto;
}
.a-market-create-save-button-container > .mcg-button {
  margin: 1rem !important;
}


.a-market-create-sale-details-container {
  margin: 0 1rem;
}

.a-market-sale-visible-wrap {
  border: 1px solid #ddd;
  margin: 1rem 0;
  padding: 1rem 1.5rem;
}

.a-market-sale-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}
.a-market-sale-toggle-label {
  margin-left: 1rem;
  font-weight: bold;
}

.a-market-sale-top-descriptor {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9em;
}

.a-market-sale-details {
  margin: 1rem 0 0 0;
}

.a-market-sale-details-date {
  display: flex;
  align-items: center;
}

.a-market-sale-details-discount {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.a-market-sale-cell {
  padding: 0 1.5rem 0 0;
}

.a-market-sale-details-discount-type select {
  cursor: pointer;
  border-radius: 0.25rem;
  border-color: rgba(0, 0, 0, 0.15);
  padding: 0.4rem 0.5rem;
  width: 150px;
}
.a-market-sale-details-discount-value input {
  padding: 0.6rem;
  width: 300px;
}

.react-date-picker__wrapper {
  border: 1px solid #ddd !important;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #fff;
}

.react-date-picker__button {
  display: none;
}

.a-market-no-items {
  padding: 3rem;
  text-align: center;
}

.am-category-new-label {
  font-size: 0.9em;
  font-style: italic;
}

.am-category-new-toggle {
  font-size: 0.8em;
  font-style: italic;
  padding: 0.25rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
}



.mcg-add-category-button {
  padding: 0.25rem 1.5rem !important;
}

.mcg-manage-category-button {
  text-align: left;
  padding: 0;
  margin: 0;
}

.a-market-image-list {
  display: flex;
  position: relative;
}

.a-market-image-primary{
  position: absolute;
  padding: 0.15rem 0.5rem;
  font-size: 0.8em;
  background-color: var(--a-theme);
  color: #fff;
}

.a-market-image-item {
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid var(--a-border);
  background-color: var(--a-secondary);
  overflow: hidden;

  width: 100px;
  height: 100px;
}
.a-market-image-item img {
  display: block;
  max-width: 80px;
  max-height: 80px;
}

.a-market-image-remove-button {
  cursor: pointer;
  text-align: center;
  color: rgb(141, 17, 17);
  padding: 0.25rem;

  transition: color 0.2s ease;
}
.a-market-image-remove-button:hover {
  color: rgb(211, 25, 25);
}


.a-market-category-management {
  max-height: 1500px;
  overflow: hidden;
  
  transition: max-height 0.4s ease-out;
}
.a-market-category-management.hide {
  max-height: 0px;
}

.am-category-management-list {
  margin-top: 1rem;
}
.am-category-management-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
}
.am-category-management-label, .am-category-management-label input {
  width: 100%;
}
.am-category-management-remove {
  padding: 0 0.5rem;
}


.am-create-stat-wrapper {
  display: flex;
  align-items: center;
}

.am-create-stat-section {
  padding: 0.5rem 2rem;
  text-align: center;
}

.am-create-stat-label {
  font-style: italic;
}

.am-create-stat-value {
  font-size: 2rem;
  padding: 0.5rem;
}

.ma-oi-total-wrapper {
  display: flex;
}
.ma-oi-total-label {
  font-weight: bold;
}
.ma-oi-total-value {
  margin-left: auto;
  font-style: italic;
  font-size: 1.2rem;
}

.mcg-rollback-cancel {
  padding: 1rem 2rem;
  
}

.checkout-delivery-area {
  width: 100%;
  padding: 0.5rem;
  border: none;
  box-shadow: 0 2px 2px rgba(0,0,0,.05), 0 2px 4px rgba(0,0,0,.05);
}




.a-market-create-item-settings-section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 1rem 0;
  margin: 0rem 1rem 1rem 1rem;
}

.a-market-create-item-settings-section {
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
}
.a-market-create-item-settings-section-header-stock-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
}
.a-market-create-item-settings-section-header-stock-option.taxable-option {
  margin-left: auto;
  margin-right: 2rem;
}

.a-market-create-item-settings-section-header-stock-option-label {
  margin-right: 1rem;
  width: 150px;
}


.a-market-create-sale-details-section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 1rem 0;
  margin: 0rem 1rem 0 0;
}