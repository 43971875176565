.auto-item-list {
  width: 100%;
  padding: 2rem;
}

.auto-item {
  padding: 1.75rem 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
}
.auto-item:hover {
  background-color: var(--highlight);
}

.auto-item-image {
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  margin-bottom: 1rem;
  text-align: center;
}

.auto-item-image img {
  object-fit: cover;
  object-position: 50% 50%;
  margin: auto;
  max-width: 100%;
}

.auto-item-title {
  font-size: 1.2em;
  font-weight: bold;
}

.auto-item-details div {
  font-size: 0.9em;
  font-weight: bold;
}

.auto-list-detail {
  font-weight: normal;
  font-style: italic;
}

.auto-item-price {
  font-size: 1.2em;
}


.hide-mobile, .hide {
  display: none;
}

.auto-details-title {
  font-weight: bold;
  font-size: 1.75em;
}

.auto-details-price {
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1.25em;
}

.detail-item {
  padding: 0.15rem;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.detail-item-label {
  font-weight: bold;
  display: block;
  text-align: right;
  width: 80px;
  margin-right: 1rem;
}

.auto-description {
  padding: 1rem 0;
}
.auto-description h3 {
  padding: 1rem 0;
}

@media (min-width: 720px) {
  .auto-item-list {
    max-width: 1200px;
    margin: 0 auto;
  }

  .auto-item {
    display: flex;
  }
  
  .auto-item-image {
    width: 200px;
    max-height: 175px;
    margin-bottom: 0;
    margin-right: 2rem;
  }
  .auto-item-image img {
    position: relative;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);
  }

  .auto-item-title {
    margin-bottom: 0.75rem;
  }

  .auto-item-area {
    margin-right: auto;
  }

  .auto-item {
    padding: 1.5rem 2rem;
    text-align: left;
  }
}