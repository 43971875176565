.mcg-drop-area p {
  margin: 0;
  padding: 0;
}

.mcg-upload {
  display: flex;
  flex-direction: column;
}


.mcg-drop-area {
  margin: 5px;
  padding: 20px;

  text-align: center;

  color: var(--fontdarksub);
  background-color: var(--a-highlight);
  border: solid 1px var(--a-border);

  cursor: pointer;
  transition: background-color .2s ease-in-out;
}
.mcg-drop-area:hover {
  background-color: var(--a-highlight);
}

.mcg-drop-area.disabled {
  background-color: var(--a-highlight);
  border: solid 1px var(--a-border);
  cursor: progress;
}

.mcg-drop-area p {
  border: 2px dashed var(--a-border);
  padding: 50px;
}

.mcg-title {
  color: var(--a-fontdarksub);
}

.mcg-notice {
  color: var(--a-fontlight);
  background-color: var(--a-theme);
  text-align: center;

  border-radius: 5px;

  margin: 0;
  padding: 0;

  transform: scale(0);
  transition-timing-function: cubic-bezier(.43,.58,.68,1.5);
  transition-duration: 0.2s;
}
.mcg-notice.mcg-success {
  background-color: var(--success);
}
.mcg-notice.mcg-failed {
  background-color: var(--error);
}
.mcg-popin {
  margin: 5px;
  padding: 15px 15px;
  
  transform: scale(1);
}

.mcg-large-text {
  font-size: 2em;
}




.mcg-small-upload .mcg-drop-area {
  padding: 15px;
}
.mcg-small-upload .mcg-drop-area p {
  border: 2px dashed var(--a-border);
  padding: 30px;
}
.mcg-small-upload .mcg-large-text {
  font-size: 1.25em;
}

.mcg-max-width { 
  max-width: 100%;
}

.mcg-max-width-250px {
  max-width: 250px;
}