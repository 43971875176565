.photos-slider {
  position: relative;
}

.gallery-header {
  padding: 2rem 0;
}

.gallery-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  overflow: hidden;
}

.gallery-row {
  display: flex;
  flex-direction: row;
  width: 150%;
  
  margin-top: -10px;
  margin-bottom: -10px;

  margin-left: 50%;
  transform: translateX(-50%);
}

.images-item {
  margin: 0 5px;
  width: 100%;
}
.images-item:first-child {
  margin-left: 0;
}
.images-item:last-child {
  margin-right: 0;
}

.images-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 400px;
}

.gallery-view-button {
  position: absolute;
  z-index: 30;
  bottom: 70px;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  margin: 0 auto;
  width: 100%;
  text-align: center;

}

.gallery-button {
  background-color: var(--backgroundlight);
  color: var(--fontdark);

  padding: 1rem 2rem;
  border-radius: 100px;

  text-transform: uppercase;

  cursor: pointer;

  transition: background-color 0.2s ease-in-out;
}

.gallery-button:hover {
  background-color: var(--highlight);
}

.gallery-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  width: 100%;
  max-height: 800px;
  height: 50%;
  background: linear-gradient(0deg,rgba(28,28,28,0.7) 0,rgba(28,28,28,0));
}

.gallery-left-side-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 20;
  width: 15%;
  max-width: 400px;
  height: 100%;
  background: linear-gradient(90deg,rgba(28,28,28,0.5) 0,rgba(28,28,28,0));
}

.gallery-right-side-gradient {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 20;
  width: 15%;
  max-width: 400px;
  height: 100%;
  background: linear-gradient(90deg,rgba(28,28,28,0) 0,rgba(28,28,28,0.5));
}

@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .gallery-row {
    margin-top: 0;
    margin-bottom: 0;
  }
  .images-item {
    margin: 0 15px;
  }
}