.mcg-status {
  color: var(--fontlight);
  background-color: var(--theme);
  text-align: center;

  border-radius: 5px;

  margin: 0;
  padding: 0;

  transform: scale(0);
  transition: transform 0.2s cubic-bezier(.43,.58,.68,1.5);
}
.mcg-status.mcg-success {
  background-color: var(--success);
}
.mcg-status.mcg-failed {
  background-color: var(--error);
}
.mcg-popin {
  margin: 5px;
  padding: 15px 15px;
  
  transform: scale(1);
}