.hero-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 0;
  min-height: 300px;
  width: 100%;

  transition: padding-top 0.1s ease-in-out;
}

.h-sticky + .hero-image {
  padding-top: 102px;
}


.sub-hero-dark {
  position: relative;
  background: rgb(46,46,46);
  background: linear-gradient(180deg, rgba(46,46,46,1) 0%, rgba(0,0,0,1) 100%);
  height: 250px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: margin-top 0.2s ease;
}
.sub-hero-image {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  opacity: 0.3;

  transition: padding-top 0.1s ease-in-out;
}

.sub-header-title {
  position: absolute;
  color: #fff;
  font-size: 2em;
}



@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .hero-image {
    min-height: 500px;
  }
}