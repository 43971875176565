.hours-definition {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: left;
}

.hours-definition div label {
  font-size: 0.8em;
  color: var(--a-fontdarksub);
  font-style: italic;
  padding-right: 0.25rem;
}

.hours-definition {
  border: 1px solid var(--a-border);
  background-color: var(--a-secondary);
  padding: 0.75rem 1rem;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
}

.hours-day-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hours-opened-group {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.hours-opened-group label {
  padding-right: 1rem !important;
}

.hours-time {
  display: flex;
  justify-content: space-around;
  width: 100%;

}

.hours-appointment-group {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
}

.hours-appointment-group label {
  padding-right: 1rem !important;
}

.hours-opened, .hours-appointment {
  max-width: 100px;
}
.hours-day {
  max-width: 150px;
  font-size: 1.5em;
}

.hours-time-picker {
  border: 1px solid var(--a-border);
  background-color: var(--a-backgroundlight);
  border-radius: 0.25rem;
  padding: 0.1rem 0 0.1rem 0.2rem;
  color: var(--a-fontdark);
}

.hours-time-picker .react-time-picker__wrapper {
  border: none;
}

@media (min-width: 1160px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .hours-definition {
    display: flex;
    flex-direction: row;
  }

  .hours-definition div {
    flex-grow: 1;
  }
  .hours-time, .hours-day-group, .hours-appointment-group {
    justify-content: flex-start;
  }

  .hours-day-group {
    max-width: 350px;
  }
  .hours-appointment-group {
    max-width: 230px;
  }


  .hours-time-select {
    display: flex;
    align-items: center;
  }
  .hours-time-select:first-child {
    padding-right: 1rem;
  }
}