footer {
  background-color: #171720;
  color: var(--fontlight);

  padding: 2.5rem 3rem;
  width: 100%;
}

footer a {
  color: var(--fontlight);
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

footer a:hover {
  color: var(--linkfooter);
}

footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.f-login {
  margin-right: 0 !important;
  margin-left: auto;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  margin-bottom: 2rem;
}
.footer-links a {
  display: block;
  padding: 0.75rem 0;
  flex: 1 1 100%;
}


.footer-information {
  display: flex;
  margin-bottom: 2rem;

  align-items: center;
}

.f-social {
  display: flex;
  width: 100%;
}

.f-social-link {
  font-size: 1.6rem;
  font-weight: 400;
  flex-grow: 1;
  /*padding: 1.5rem 2rem 0.75rem 2rem;*/
  transition: background-color 0.2s ease-in-out;
}

.f-social-link:hover {
  background-color: var(--theme);
}

.f-social-icon {
  width: 100%;
  text-align: center;
}

.f-social-a {
  border-top: none;
  display: flex;
  padding: 1.25rem 0.25rem;
  transition: color 0.2s ease-in-out;
}

.f-social-a:hover {
  color: var(--fontlight);
}

.f-social-label {
  flex-grow: 1;
  display: none;
}

footer .footer-credit {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  font-size: 0.8em;
  text-align: center;
}

.f-location {
  width: 100%;
}

.f-copyright {
  width: 100%;
}


@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
  footer {
    padding: 3.75rem 5rem;
  }

  .footer-links {
    text-align: left;
    margin-bottom: 5rem;
    /*flex-wrap: nowrap;*/
  }
  .footer-links a {
    display: block;
    margin-right: 4rem;
    padding: 0.25rem 0;
    flex: 0;
    white-space: nowrap;
  }
  .footer-links a:last-child {
    margin-right: 0;
  }

  .footer-information {
    display: flex;
    margin-bottom: 3.5rem;
  
    align-items: center;
  }

  .f-social-link {
    flex-grow: 0.2;
    margin-right: 2.5rem;
  }
  .f-social-a {
    border-top: solid 1px var(--backgroundlight);
  }
  .f-social-icon {
    width: inherit;
    text-align: right;
  }

  .f-social-label {
    display: block;
  }


  footer .footer-credit {
    flex-direction: row;
  
    text-align: left;
    line-height: 1.15rem;
  }

  .f-location {
    flex-grow: 1;
  }
  .f-copyright {
    text-align: right;
  }
}