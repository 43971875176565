.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.contact-details {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  min-width: 350px;

  padding: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9em;
  line-height: 1.5em;
}

.c-call {
  font-size: 2em;
  line-height: 1.25em;
  max-width: 400px;
}

.c-call p {
  margin-top: 0;
}

.c-details {
  font-weight: 300;
  padding: 1.5rem 0 0 0;
}

.form-field-line {
  margin: 0.5rem 0;
}
.form-field-grouping {
  display: flex;
}

.form-element {
  box-sizing: border-box;
  display: block;
  width: 100%;

  margin: 0.5rem 0 1rem 0;
  padding: 0.5rem;

  border-radius: 0.15rem;

  box-shadow: none;
  border: 1px solid var(--border);

  transition: border-color 0.2s ease-in-out;
}
.form-element:hover {
  border-color: var(--theme);
}

.form-button {
  background-color: var(--theme);
  color: var(--fontlight);
  padding: 1rem;
  border-radius: 0.25rem;
  font-family: 'Fjalla One', sans-serif;
  font-size: 1.5em;

  cursor: pointer;

  transition: background-color 0.2s ease-in-out;
}
.form-button:hover {
  background-color: var(--linkdarkhover);
  color: var(--fontlight);
}

section:nth-child(odd) .form-button {
  color: var(--theme);
  background-color: var(--highlight);
}
section:nth-child(odd) .form-button:hover {
  background-color: var(--highlighthover);
}



@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .contact-container {
    margin: 1rem auto 0 auto;
  }
  .contact-details {
    text-align: left;
  }
}