.mcg-button {
  cursor: pointer;
  display: block;

  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  width: inherit;
  color: var(--a-fontlight);

  background-color: var(--a-theme);
  border-radius: 0.25rem;
  padding: 0.65rem 1.5rem;
  margin: 0.5rem;

  transition: background-color 0.2s ease-in-out;
}
.mcg-button i {
  padding: 0 0.1rem 0 0;
}
.mcg-button:hover {
  background-color: var(--a-themelight)
}

.mcg-cancel {
  color: var(--a-theme);
  border: 1px solid var(--a-theme);

  background-color: #fdfdfd;
}
.mcg-cancel:hover {
  color: #fff;
}