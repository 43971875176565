.files-list {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.files-item {
  margin: 0 0.5rem 2rem 0.5rem;
  min-width: 300px;
}

.file-indicator {
  display: flex;
  flex-direction: column;
}

.file-indicator i {
  font-size: 5em;
  margin-bottom: 0.5rem;
}

.file-image {
  max-width: 250px;
}
.file-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}