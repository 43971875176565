.video-list {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 2rem auto 0 auto;
}

.video-item {
  max-width: 300px;
  margin-bottom: 2rem;
}
.video-item:last-child {
  margin-bottom: 1rem;
}

.video-image-area {
  height: 160px;
  overflow: hidden;
}

.video-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-item a {
  text-decoration: none;

  transition: color 0.2s ease-in-out;
}
.video-item a:hover {
  color: var(--fontdark);
}


@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .video-item {
    margin-right: 2rem;
  }
  .video-item:last-child {
    margin-bottom: 2rem;
  }
}