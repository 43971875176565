.mcg-links-item {
  display: flex;
  align-items: center;

  padding: 10px 20px 10px 10px;
  margin: 5px 10px;
  
  border: 1px solid var(--a-border);
  background-color: var(--a-secondary);

  border-radius: 0.25rem;

  cursor: pointer;
  overflow: hidden;
}

.mcg-links-item i {
  font-size: 0.8em;
  color: var(--a-fontgrey);
}

.mcg-links-icon-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 0 0;
  color: var(--a-fontgrey);
}
.mcg-links-icon-area i {
  padding-top: 4px;
}



.mcg-links-order {
  padding: 0 10px;
}

.mcg-links-descriptors {
  text-align: left;
}

.mcg-links-url {
  font-style: italic;
  color: var(--a-fontgrey);
  display: block;
}

.mcg-links-area {
  display: flex;
  flex-direction: column;
}

.mcg-links-area select {
  height: 38px;
  font-size: 1.2em;
  padding: 5px 10px;
  color: var(--a-fontdarksub);
  background-color: var(--a-backgroundlight);
  background-clip: padding-box;
  border: 1px solid var(--a-border);
  border-radius: .25rem;
  transition: border-color .25s ease-in-out;
}

.mcg-links-area select:hover {
  border-color: var(--a-themelight);
}

.mcg-flex-grow {
  flex-grow: 1;
}



@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .mcg-links-area {
    flex-direction: row;
  }
  .mcg-links-icon-area {
    flex-direction: row;
  }
  .mcg-links-url {
    padding: 0 25px;
  }
  .mcg-links-descriptors {
    display: flex;
  }
}