.restaurant-wrapper {
  width: 100%;
}

.restaurant-container {
  padding: 3rem 2.5rem;
}

.restaurant-day-select {
  padding: 3rem 2.5rem 0rem 2.5rem;
}
.restaurant-day-select h2 {
  padding: 0 0rem 1rem 0rem;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.restaurant-day-group {
  display: flex;
  flex-direction: column;
}
.restaurant-day {
  flex-grow: 1;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.25rem;

  transition: background-color 0.2s ease;
}
.restaurant-day:hover {
  background-color: var(--highlight);
}
.restaurant-day.selected {
  background-color: var(--theme);
  color: var(--fontlight);
}


.restaurant-time {
  padding: 1rem 0;
}
.restaurant-time h3 {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  margin: 0 0rem 1rem 0rem;
  border-bottom: 2px solid var(--fontdark);
}

.restaurant-block-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.restaurant-item {
  display: flex;
  flex-direction: column;
  font-size: 1.25em;
  padding: 0.5rem;
  align-items: center;
}

.restaurant-block-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.25em;
  padding: 0.5rem;
  align-items: center;
}

.restaurant-image {
  height: 100px;
  width: 130px;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;

  overflow: hidden;
}

.restaurant-block-item .restaurant-image {
  height: 200px;
  width: 250px;
}

.restaurant-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.restaurant-info-container {
  width: 100%;
}
.restaurant-info {
  display: flex;
}

.restaurant-name {
  font-weight: bold;
  margin-right: auto;
}

.restaurant-description {
  font-size: 0.75em;
  color: var(--fontdarksub);
}

.restaurant-cost {
  text-align: right;
  padding-left: 1rem;
}

@media (min-width: 900px) {
  .restaurant-wrapper {
    max-width: 900px;
    margin: 0 auto;
  }

  .restaurant-container {
    padding: 3rem 0rem;
  }

  .restaurant-day-select {
    padding: 3rem 0rem 0rem 0rem;
  }
  .restaurant-day-group {
    flex-direction: row;
  }

  .restaurant-time h3 {
    text-align: left;
  }
  .restaurant-block-time {
    flex-direction: row;
  }
  .restaurant-image {
    height: 70px;
    width: 90px;
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .restaurant-block-item {
    width: 30%;
    margin: 1.5%;
  }
  .restaurant-block-item .restaurant-image {
    margin-bottom: 1rem;
  }

  .restaurant-item {
    flex-direction: row;
  }

  .restaurant-cost {
    padding-left: 3rem;
  }
  .restaurant-block-item .restaurant-cost {
    padding-left: 1.5rem;
  }
}