.mcg-options-item {
  display: flex;
  align-items: center;

  padding: 10px 20px 10px 10px;
  margin: 5px 10px;

  border: 1px solid var(--a-border);
  background-color: var(--a-secondary);

  cursor: pointer;
  overflow: hidden;
}

.mcg-options-order-area {
  color: var(--a-fontgrey);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0 0.5rem;
}
.mcg-options-order-area i {
  padding-right: 0.5rem;
  font-size: 1.5em;
  color: var(--a-fontgrey);
}
.mcg-options-descriptors {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.mcg-options-block {
  font-size: 0.8em;
  color: var(--a-fontgrey);
  font-style: italic;
  text-align: left;
  min-width: 80px;
}

.mcg-options-input {
  width: 100%;
}
.mcg-options-input input {
  border-radius: 0.25rem;
  padding: 0.35rem 0.5rem;
  border: 1px solid var(--a-border);
  color: var(--a-fontdark);
  width: 100%;
  max-width: 400px;
}

.mcg-options-order {
  width: 25px;
}


@media (min-width: 736px) {
  .mcg-options-order-area i {
    font-size: 0.8em;
    padding-top: 4px;
  }
  .mcg-options-order-area {
    flex-direction: row;
  }
  .mcg-options-descriptors {
    flex-direction: row;
    align-items: center;
  }
}