input {
  border-radius: 0.25rem;
  padding: 0.35rem 0.5rem;
  border: 1px solid #ddd;
  color: var(--a-fontdarksub);
}

.login-temp {
  cursor: pointer;
  text-align: center;
  padding: 2rem;
  font-size: 1.5em;
}

.login-temp ul {
  font-size: 0.6em;
  padding-top: 1rem;
}

.login-temp li {
  padding-top: 1.5rem;
}

.dash-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.75rem 1.5rem;

  border-bottom: 1px solid var(--a-border);
}

.dash-logo img {
  max-height: 40px;
}

.dash-content-wrapper {
  display: flex;
}

.dash-site-preview {
  margin-top: 1.5rem;
  font-style: italic;
}

.dash-nav-mobile {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;

  display: flex;
  flex-direction: column;

  background: linear-gradient(-90deg,#fafafa, #fff);

  padding: 1rem 1.25rem;
  min-height: 100vh;

  transform: translateX(100%);

  transition: transform 0.4s ease-in-out;
}
.dash-nav-mobile-button {
  text-align: right;
  width: 100%;
}
.dash-nav-mobile a {
  display: block;
  padding: 0.5rem 2.5rem 0.5rem 1.5rem;
  min-width: 230px;
}
.dash-nav-mobile a i {
  min-width: 30px;
}
.dash-nav-mobile h3 {
  padding: 1.5rem 1rem 0rem 1rem;
  font-size: 0.8em;
  text-transform: uppercase;
  color: var(--a-navheader);
}
.dash-nav-button {
  display: inline;
  padding-left: 1rem;
}

.dash-nav-mobile.nav-show {
  transform: translateX(0%);
}

.dash-nav {
  display: none;
  flex-direction: column;
  padding: 0 0 1rem 0;
}
.dash-nav a {
  display: block;
  padding: 0.5rem 2.5rem 0.5rem 1.5rem;
  min-width: 230px;
}
.dash-nav a i {
  min-width: 30px;
}
.dash-nav h3 {
  padding: 1.5rem 1rem 0rem 1rem;
  font-size: 0.8em;
  text-transform: uppercase;
  color: var(--a-navheader);
}

.dash-nav-link:hover {
  background: linear-gradient(-90deg,#fafafa, #fff);
}


.dash-content {
  padding: 0.5rem;
  background-color: var(--a-secondarylight);
  width: 100%;
  text-align: center;
  min-height: calc(100vh - 65px);
}

.mcg-form-button {
  width: 100%;
  padding: 15px;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--a-theme);
  border: none;
  border-radius: .25rem;
  cursor: pointer;

  transition: background-color .1s ease-in-out;
}

.mcg-form-button:hover {
  background-color: var(--a-themelight);
}


.mcg-remove {
  cursor: pointer;
  padding: 0 10px;
  color: var(--a-navheader);

  transition: color 0.2s ease-in-out;
}
.mcg-remove:hover {
  color: var(--error) !important;
}


@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .dash-nav {
    display: flex;
  }

  .dash-nav-mobile, .dash-nav-button {
    display: none;
  }

  .dash-content {
    padding: 1.5rem;
    text-align: left;
  }
}