.slider-container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: var(--backgrounddark);
  color: var(--fontlight);

  z-index: 1000;

  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translateY(150%);
  transition: transform 0.25s ease-in-out;
}

.slider-show {
  transform: translateY(0%);
}

.slider-close {
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  padding: 1.5rem;
  font-size: 1.5em;
  text-shadow: 1px 1px #000;
  cursor: pointer;
}

.aws-sld__wrapper {
  max-height: 800px;
}

.aws-btn {
  --slider-height-percentage: 60%;
  --slider-transition-duration: 670ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 26px;
  --organic-arrow-color: var(--fontdark);
  --control-button-width: 13%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: var(--linklight);
  --control-bullet-active-color: var(--linklighthover);
  --loader-bar-color: var(--fontdark);
  --loader-bar-height: 5px;
}

.aws-sld__content>img {
  object-fit: contain !important;
}

.aws-sld--exit.aws-sld--moveRight, .aws-sld--exit.aws-sld--moveLeft {
  animation: scaleOut var(--slider-transition-duration) both var(--transition-bezier);
}
@keyframes scaleOut {
  to {
    transform: scale(0.85);
  }
}

