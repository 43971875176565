.mcg-video-manager {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mcg-video-item {
  box-sizing: border-box;
  margin: 10px;
  max-width: 300px;
}

.mcg-video-thumbnail {
  max-width: 300px;
}

.mcg-video-url {
  font-size: 0.75em;
  font-style: italic;
  color: var(--a-fontdarksub);
}
.mcg-video-caption {
  font-size: 1.1em;
}

.mcg-video-icon-space {
  padding: 5px;
}



@media (min-width: 736px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .mcg-video-manager {
    flex-direction: row;
  }
}