:root {
  --theme: #315994;
  --themefont: #fff;
  --themehighlight: #4372b9;
  --secondary: #eee;

  --background: #315994;
  --backgroundlight: #ffffff;
  --backgrounddark: #171720;

  --border: #dddddd;

  --fontnavheader: #aaa;
  --fontlight: #ffffff;
  --fontdark: #171720;
  --fontdarksub: #5b5b63;

  --fontlightfade: #ddd;
  --fontdarkfade: #aaa;

  --linkdark: #315994;
  --linklight: #b2becf;
  --linkdarkhover: #171720;
  --linklighthover: #dfe4eb;
  --linkfooter: #769edb;

  --highlight: #eee;
  --highlighthover: #b7bbc0;
  --highlightdark: #171720;


  /* Admin - No change*/
  --a-theme: #315994;
  --a-themelight: #0069d9;
  --a-themedark: #495057;
  --a-secondarylight: #fdfdfd;
  --a-secondary: #fcfcfc;

  --a-navheader: #aaaaaa;
  --a-fontlight: #ffffff;
  --a-fontdark: #171720;
  --a-fontdarksub: #5b5b63;
  --a-fontgrey: #999999;

  --a-backgroundlight: #ffffff;
  --a-backgrounddark: #171720;

  --a-border: #dddddd;

  --a-highlight: #eee;

  /* Constants accross both FE and Dashboard */

  --success: #68C61E;
  --warning: #FFA000;
  --error: #B00020;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  /*font-family: 'EB Garamond', serif;
  font-family: 'Noto Sans', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5rem;
  font-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: var(--linkdark);
  text-decoration: none;
  cursor: pointer;

  transition: color 0.2s ease;
}
a:hover {
  color: var(--linkdarkhover);
}

img {
  display: block;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  /*font-family: 'Fjalla One', sans-serif;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;*/
  font-weight: 500;
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 3em;
  line-height: 1.25em;
}
h2 {
  font-size: 2em;
  line-height: 1.25em;
}
h3 {
  font-size: 1.5em;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}