.admin-nav {
  display: flex;
  margin: 1rem;
  flex-wrap: wrap;
}

.admin-link {
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  color: var(--a-theme);
  transition: color 0.2s ease;
  min-width: 125px;
}
.admin-link:hover {
  color: var(--a-themelight);
}