/* Checkout Container */

.checkout-container {
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  
  width: 100%;
  min-height: 100vh;

  overflow: hidden;
  overflow-y: scroll;

  background: #fcfcfc;

  box-shadow: 0 0 0 0 rgba(0,0,0,.15);

  transform: translateX(100%);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out, ;
}

.checkout-container.show {
  box-shadow: 10px 0 40px 0 rgba(0,0,0,0.5);
  transform: translateX(0);
}

@media (min-width: 500px) {
  .checkout-container {
    max-width: 500px;
  }
}

/* Checkout Styles */
.checkout-scroll-container {
  max-height: calc(100vh - 221px);
  overflow: hidden;
  overflow-y: scroll;

  padding: 1rem;
}
.checkout-scroll-container::-webkit-scrollbar {
  width: 4px;
}
.checkout-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}

.checkout-scroll-container.not-item-display {
  max-height: 100%;
}

.checkout-header-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;

  border-bottom: 1px solid rgba(0,0,0,0.075);
}
.checkout-header {
  margin-right: auto;
  font-size: 1.3em;
  padding: 1rem 1.5rem;
}
.checkout-close-button {
  cursor: pointer;
  padding: 0.75rem 1.15rem;

  border-radius: 0.25rem;

  transition: background 0.3s ease;
}
.checkout-close-button:hover {
  background: rgba(0,0,0,0.1);
}


.checkout-item-list-container {
  margin: 1rem;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}


.checkout-footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2.5rem 2rem;

  box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
}
.checkout-sub-total {
  display: flex;
}
.checkout-sub-total-label {
  margin-right: auto;
}
.checkout-sub-total-label, .checkout-sub-total-value {
  padding: 0 1rem;
  font-size: 1.2em;
  font-weight: bold;
}
.checkout-tax-shipping-info {
  width: 100%;
  font-size: 0.9em;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  padding: 0rem 1rem 0.5rem;
}
.checkout-button {
  cursor: pointer;

  width: 100%;
  background: var(--theme);
  color: var(--themefont);
  text-align: center;

  font-size: 1.2em;
  font-weight: bold;
  
  padding: 1rem;
  border-radius: 0.25rem;

  transition: background 0.3s ease;
}
.checkout-button:hover {
  background: var(--themehighlight);
}

.disable-payment {
  cursor: default;
  background: rgba(0, 0, 0, 0.5);
}
.disable-payment:hover {
  background: rgba(0, 0, 0, 0.5);
}


.checkout-no-items {
  padding: 5rem 3rem;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
}



/* Cart Item */
.checkout-item-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.025);
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.checkout-item-remove {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

  padding: 0.5rem 0.75rem;

  color: rgba(90, 0, 0, 0.4);

  transition: color 0.3s ease;
}
.checkout-item-remove:hover {
  color: rgba(90, 0, 0, 0.8);
}

.checkout-item-image {
  width: 80px;
  min-width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 0.25rem;
}
.checkout-item-image img {
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: cover;
}

.checkout-item-information {
  margin-right: auto;
  padding-left: 1rem;
}

.checkout-item-title {
  padding-bottom: 0.5rem;
}

.checkout-item-title, .checkout-item-price {
  font-weight: bold;
}

.checkout-item-quantity input {
  font: inherit;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 50px;
  padding: 0.4rem;
  margin: 0;
  text-align: center;
  border-radius: 0.25rem;
}
/* Chrome, Safari, Edge, Opera */
.checkout-item-quantity input::-webkit-outer-spin-button,
.checkout-item-quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.checkout-item-quantity input[type=number] {
  -moz-appearance: textfield;
}


/* Cart */
.checkout-cart-button {
  position: fixed;
  right: 0;
  top: 0;

  cursor: pointer;
  background: #eee;

  border-radius: 0 0 0 0.25rem;

  transition: background 0.3s ease;
}
.checkout-cart-button:hover {
  background: #ddd;
}
.checkout-cart-icon {
  padding: 1rem 1.2rem;
}
.checkout-cart-count {
  position: absolute;
  right: 6px;
  top: 10px;

  width: 16px;
  height: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.6em;
  text-align: center;
  background: var(--themehighlight);
  color: var(--themefont);

  border-radius: 50%;
}


.checkout-cart-view, .checkout-shipping-view,
.checkout-payment-view, .checkout-complete-view {
  position: fixed;
  width: 100%;
  height: 100vh;

  transform: translateX(0);

  transition: transform 0.2s ease-in-out;
}

.checkout-cart-view.right,
.checkout-shipping-view.right,
.checkout-payment-view.right,
.checkout-complete-view.right {
  transform: translateX(100%);
}

.checkout-cart-view.left,
.checkout-shipping-view.left,
.checkout-payment-view.left,
.checkout-complete-view.left {
  transform: translateX(-100%);
}

.checkout-card-container {
  width: 100%;
  margin-bottom: 1rem;
}

.checkout-cost-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.checkout-cost-item-image {
  width: 50px;
  margin-right: 0.5rem;
}
.checkout-cost-item-image img {
  width: 100%;
}
.checkout-cost-item-name {
  margin-right: auto;
}
.checkout-cost-item-name-subline {
  font-size: 0.9em;
  font-style: italic;
}

.checkout-review {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}

.review-currency {
  width: 100px;
}



.mcg-form {
  background-color: #fff;
  width: 100%;
  border-radius: 0.25rem;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
}

.mcg-form-input {
  display: flex;
  padding: 0.4rem 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.mcg-form-input .mcg-label {
  width: 200px;
  text-align: right;
  padding: 0.25rem 1rem 0.25rem 0.25rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0)
}
.mcg-form-input .mcg-input {
  width: 100%;
}
.mcg-form-input .mcg-input input {
  border: none;
  width: 100%;
  padding: 0.5rem;

  border-bottom: 1px solid rgba(44, 88, 184, 0);
  transition: border-bottom 0.2s ease;
}
.mcg-form-input .mcg-input input:focus {
  outline: none;
  border-bottom: 1px solid var(--theme);
}
.mcg-form-input .mcg-input input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.StripeElement {
  background: #fff;
  padding: 0.75rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.05);
}

.checkout-error {
  background-color: rgb(161, 20, 20);
  color: #fff;
  text-align: center;
  padding: 1rem;
  border-radius: 0.25rem;
  z-index: 10;
  display: flex;
  align-items: center;
}
.checkout-error-close {
  padding: 1rem;
  cursor: pointer;
  font-size: 1.2em;
  
  transition: color 0.2s ease;
}
.checkout-error-close:hover {
  color: rgba(255, 255, 255, 0.8);
}

.checkout-success-check {
  font-size: 10em;
  color: #00c851;
  text-align: center;
  width: 100%;
}

.checkout-success-header, .checkout-success-message {
  text-align: center;
  width: 100%;
}

.checkout-radio {
  padding: 1rem;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.checkout-radio label {
  cursor: pointer;
}
.checkout-radio input {
  margin-right: 1rem;  
}

.checkout-validation-message {
  background-color: var(--error);
  color: #ffffff;
  margin: 0 0.5rem 1rem 0.5rem;
  padding: 1rem;
  text-align: center;
  border-radius: 0.25rem;
}