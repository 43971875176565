.ma-order-item {
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem 0.5rem;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.05);
  border-bottom: 0px;

  transition: 0.2s background-color ease;
}

.ma-order-item:hover {
  background: #fcfcfc;
}

.ma-order-item-order {
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
  font-style: italic;
}

.ma-order-item-name {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  min-width: 200px;
  text-align: left;
  flex-grow: 1;
  width: 100%;
}

.ma-order-item-extra-details {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  text-align: right;
}

.ma-order-item-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}

.ma-order-item-order-status {
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
  text-align: right;
}

.ma-order-item-item-count {
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
  text-align: right;
}

.ma-order-item-total {
  font-style: italic;
  min-width: 70px;
}

.ma-order-status-filters {
  padding: 1rem;
}

.ma-order-filter-links {
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  color: var(--theme);
}
.ma-order-filter-links:hover {
  color: var(--themehighlight);
}

@media (min-width: 720px) {
  .ma-order-item-name {
    align-items: center;
    flex-direction: row;
  }

  .ma-order-item {
    flex-direction: row;
  }

  .ma-order-item-extra-details {
    align-items: center;
  }
}

@media (min-width: 900px) {
  .ma-order-item-item-count {
    margin-right: 1rem;
  }
  .ma-order-item-order-status {
    margin-right: 2rem;
  }

  .ma-order-item-details {
    flex-direction: row;
  }
}



.ma-oi-order {
  width: 100%;
}

.ma-oi-order-number {
  font-size: 2em;
  margin-bottom: 0.25rem;
}

.ma-oi-order-time {
  font-style: italic;
  color: rgba(0,0,0,0.6);
}

.ma-oi-order-status {
  margin-bottom: 1rem;
}

.ma-oi-order-wrapper {
  display: flex;
  justify-content: space-between;
}

.ma-oi-group {
  background-color: #fff;
  padding: 1rem 2rem;
}

.ma-oi-items {
  flex-grow: 3;
  margin-right: 1rem;
}

.ma-oi-customer {
  flex-grow: 1;
}


.ma-oi-section {
  margin: 1rem 0;
}

.ma-oi-line-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}
.ma-oi-line-item:nth-child(even) {
  background-color: rgba(0,0,0,0.05);
}

.ma-oi-line-item-img {
  border-radius: 0.25rem;
  overflow: hidden;
  width: 50px;
  height: 50px;

  margin-right: 1rem;
}

.ma-oi-line-item-img img {
  max-width: 50px;
  max-height: 50px;
}

.ma-oi-line-item-title {
  margin-right: auto;
}



.ma-oi-tracking-group {
  background-color: rgba(0,0,0,0.05);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
}

.ma-oi-tracking {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.ma-oi-tracking-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.ma-oi-tracking-group .mcg-button {
  margin: 1rem 0 0.5rem 0 !important;
}

.ma-oi-section-notes {
  padding: 1rem;
}