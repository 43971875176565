.nav-container {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;

  background-color: var(--backgrounddark);
  color: var(--fontlight);
  padding: 2rem 2.75rem;
  min-height: 100vh;

  transform: translateX(100%);

  transition: transform 0.4s ease-in-out;
}

.nav-container.nav-show {
  transform: translateX(0%);
}

.nav-container a {
  color: var(--fontlight);
  text-decoration: none;
}
.nav-container a:hover {
  text-decoration: underline;
}
.nav-container ul {
  list-style: none;
  padding: 0;
  margin: 0;

  text-align: center;
}

.nav-header {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 3rem;
}

.nav-header-label {
  flex-grow: 1;
}

.nav-list li a {
  font-size: 1.4rem;
  display: block;
  padding: 1.0rem 0.25rem;
}

.nav-header-button {
  cursor: pointer;
  display: block;
  padding: 0.25rem;
}

.navi-link-effect {
  animation-delay: calc(var(--animation-order) * 50ms);
  animation-duration: 1s;
}


@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .nav-container {
    max-width: 400px;
  }
}