:root {
  --theme: #2d4c7f;
  --fontlight: #ffffff;
  
  --fontdarksub: ##5b5b63;

  --error: #B00020;
}

* {
  box-sizing: border-box;
}

.marketplace-wrapper {
  width: 100%;
}


.marketplace-item-area {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.marketplace-item-area h1 {
  text-align: center;
}

.alt-filters {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;

  justify-content: center;
  align-items: center;
}

.alt-filter-header {
  margin-left: 2rem;
  font-size: 1.2em;
  font-weight: 500;
}

.filter-hide {
  display: none;
}

.alt-filter-sort-container {
  min-width: 30%;
}

.alt-filter-sort {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}


.alt-filter-sort div {
  margin: 0.25rem 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  cursor: pointer;
  transition: background-color 0.2s ease, border 0.2s ease;
}
.alt-filter-sort div:hover {
  background-color: var(--theme);
  color: var(--fontlight);
}
.alt-filter-sort div.selected {
  background-color: var(--theme);
  color: var(--fontlight);
}

.item-filters {
  text-align: center;
  padding: 2.5rem 0 0rem 0;
}

.item-filters h2 {
  padding: 1.5rem 0rem 0.5rem 0.5rem;
  font-size: 0.8em;
  text-transform: uppercase;
  color: var(--fontnavheader);
}

.item-filter-categories{
  min-width: 200px;
}

.item-filter-categories div {
  width: 100%;
  margin: 0.25rem 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;

  cursor: pointer;
  transition: background-color 0.2s ease, border 0.2s ease;
}
.item-filter-categories div:hover {
  background-color: var(--theme);
  color: var(--fontlight);
}
.item-filter-categories div.selected {
  background-color: var(--theme);
  color: var(--fontlight);
}


.item-list-wrapper {
  width: 100%;
  padding: 4rem 3rem 2.5rem 3rem;
  flex-grow: 1;
}

.item-block-display-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;

  transition: max-height 0.2s ease;
}

.block-item {
  position: relative;
  flex: 1 1 25%;
  padding: 1rem;
  min-width: 100%;

  cursor: pointer;
  overflow: hidden;
  border-radius: 0.25rem;

  transition: background-color 0.2s ease;
}
.block-item:hover {
  background-color: var(--highlight);
}
.block-item img {
  transition: opacity 0.2s ease;
}
.block-item:hover img {
  opacity: 0.85;
}

.block-item-title {
  font-weight: bold;
}

.block-item-content {
  display: flex;
}
.block-item-description {
  width: 100%;
}

.block-item-tag {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0.5rem;
  padding: 0.4rem 1rem;
  font-size: 1.1em;
  font-weight: bold;
  color: var(--fontlight);
  background-color: var(--error);
  border-radius:  0 0.25rem 0.25rem 0;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(60,60,60,0.2);
  -moz-box-shadow: 2px 2px 2px 0px rgba(60,60,60,0.2);
  box-shadow: 2px 2px 2px 0px rgba(60,60,60,0.2);
}

.item-details-tag {
  width: inherit;
  text-align: center;
  padding: 0.4rem 1rem;
  font-size: 1.1em;
  color: var(--fontlight);
  font-weight: bold;
  background-color: var(--error);
}

.item-details-back-link {
  padding: 0.5rem 0.25rem;
  cursor: pointer;
}

.block-item-brand {
  font-size: 0.9em;
  color: var(--fontdarksub);
  font-style: italic;
}
.block-item-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.block-item-starting-at {
  width: 100%;
}

.block-item-price-value-display {
  margin-left: auto;
  text-align: right;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

}

/*.block-item-img {
  height: 300px;
  width: 100%;
  border-radius: 0.25rem;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.block-item-img img {
  width: 100%;
  opacity: 1;
}*/

.block-item-img {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 200px;
}
.block-item-img img {
  flex-shrink: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
}

.block-item-content {
  padding: 1rem 0.5rem;
}

.sale-crossout {
  color: rgba(0,0,0,0.5);
  text-decoration: line-through;
  font-size: 0.9em;
  font-style: italic;
  margin-right: 0.5rem;
}



.mcg-button {
  cursor: pointer;
  background-color: #333;
  color: #fff;
  display: block;
  text-decoration: none;

  text-align: center;
  width: 300px;
  margin: 2rem auto !important;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 1.1em;
  border-radius: 0.25rem;

  transition: background-color 0.2s ease;
}
.mcg-button:hover {
  background-color: #222;
}


.more-item-loader {
  margin-right: 0.5rem;
}

.rotate {
  animation: rotation 2s infinite linear;
}

.filter-hide {
  display: none;
}


.item-details-wrapper {
  width: 100%;
  padding: 3rem;
}

.item-details-container {
  display: flex;
  flex-direction: column;
}

.item-details-images {
  width: 100%;
}
.item-details-sidearea {
  width: 100%;
  padding-top: 2rem;
  text-align: center;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  width: 30px !important;
}
.image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
  width: 24px !important;
}

.item-details-title {
  font-weight: bold;
}

.item-details-expiry {
  font-style: italic;
}

.item-details-description {
  margin: 1rem 0;
  line-height: 1.7em;
  color: var(--fontdarksub);
}

.item-details-button {
  text-align: center;
}

.item-details-prices {
  font-size: 1.5em;
}
.item-details-prices div {
  padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.item-details-sale-savings {
  font-size: 0.6em;
  color: var(--fontdarksub);
  font-style: italic;
}

.mcg-div-to-link {
  color: var(--themelink);
  text-decoration: underline;
}
.mcg-div-to-link:hover {
  color: var(--themelinkhover);
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media (min-width: 720px) { /* 480px, 736px, 980px, 1280px, 1690px */
  .marketplace-item-area {
    flex-direction: row;
    max-width: 1260px;
    margin: 0 auto;
  }
  .marketplace-item-area.filter-row {
    flex-direction: column;

  }
  .item-details-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  .item-details-container {
    flex-direction: row;
  }

  .item-details-images {
    width: 50%;
  }

  .item-details-sidearea {
    width: 50%;
    padding-top: 0rem;
    padding-left: 3rem;
    text-align: left;
  }

  .item-filters {
    text-align: left;
    padding: 2.5rem 0 2.5rem 3rem;
  }

  .block-item {
    min-width: 150px;
    max-width: 22%;
  }
}

.add-to-cart {
  box-sizing: border-box;
  text-align: center;

  padding: 0.75rem 1rem;

  background-color: #333;
  color: #fff;

  text-transform: uppercase;
  border-radius: 0.25rem;

  transition: background-color 0.1s ease, color 0.1s ease;
}

.added-to-cart {
  background-color: #68C61E !important;
  color: #333 !important;
}

.out-of-stock {
  background-color: #ddd !important;
}

.item-variation-list-wrapper h3 {
  font-weight: 500;
}

.item-variation-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.item-variation-option {
  cursor: pointer;
  padding: 0.75rem 1rem;
  margin: 0.25rem;
  border: solid 1px rgba(0,0,0,0.5);

  transition: background-color 0.2s ease;
}
.item-variation-option.selected {
  border: solid 2px rgba(25, 87, 138, 1);
}
.item-variation-option:hover {
  background-color: rgba(0,0,0,0.05);
}

.item-variation-option.out-of-stock {
  color: #aaa;
  border-color: #ddd;
}


.market-company-profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  margin-top : 3rem;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 0.25rem;
}
.market-company-profile-container > div {
  min-width: 100%;
  text-align: center;
  padding: 0.5rem;
}
.market-company-profile-logo img {
  max-width: 150px;
}

.mcpro-title {
  font-size: 1.2em;
  font-weight: 500;
}





.filter-search {
  padding: 0 2rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
.filter-search input {
  width: 100% !important;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
}

.a-market-filter-search input::placeholder {
  font-size: 0.9em;
}

@media (min-width: 900px) {
  .filter-search {
    width: inherit;
    margin-right: auto;
    padding: 0;
    margin-bottom: 0;
  }
  .filter-search input {
    width: 400px;
  }
}

.item-details-shipable {
  display: none;
  padding: 1rem 1rem 1rem 0;
  font-style: italic;
}
.item-details-shipable.show {
  display: inherit;
}



.payment-calculator-toggle {
  cursor: pointer;
  font-size: 1.2em;
  color: rgba(52, 19, 95, 1);
  transition: color 0.2s ease;
}
.payment-calculator-toggle:hover {
  color: rgba(52, 19, 95, 0.8);
}

.payment-calculator {
  margin-top: 1rem;
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 0.25rem;
  width: 300px;
  
  padding: 0rem;
  max-height: 0px;
  overflow: hidden;

  transition: max-height 0.2s ease, padding 0.2s ease;
}
.payment-calculator.show {
  max-height: 400px;
  padding: 1rem;
  border: 1px solid rgba(0,0,0,.1);
}

.payment-calculator h3 {
  text-align: center;
  font-size: 1.4em;
  margin: 0 0 1rem 0;
}

.payment-calculator-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0;
}
.payment-calculator-section input, .payment-calculator-section select {
  padding: 0.5rem 0.25rem;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.25rem;
  width: 100px;
  text-align: center;
}

.payment-calculator-label {
  width: 80px;
  text-align: right;
  margin-right: 1rem;
}

.payment-calculator-button {
  cursor: pointer;
  padding: 0.65rem 1.15rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  margin: 0.25rem;
}
.payment-calculator-button.selected {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.payment-calculator-payments-section {
  font-size: 1.3em;
  margin: 0.5rem 0;
}